<template>
  <div style="width: 500px; height: auto" justify="center">
    <v-dialog
      :fullscreen="$vuetify.breakpoint.xsOnly"
      persistent
      v-model="show"
      max-width="500px"
      max-height="800px"
      scrollable
    >
      <div class="text-center" v-if="loading" style="min-height: 500px">
        <v-progress-circular
          :size="100"
          :width="10"
          style="
            text-align: center;
            padding-top: 20px;
            padding-bottom: 20px;
            margin-top: 200px;
          "
          color="#7253CF"
          indeterminate
        ></v-progress-circular>
      </div>
      <v-card width="500px" v-if="!loading">
        <v-card-title height="100px" dark color="#7253cf">
          <div class="d-flex">
            <img
              src="@/assets/basketLogoDialogDistrictManagement.svg"
              height="85"
              width="100"
            />
          </div>
          <div class="ml-5 mb-3 dialogTitle-text">
            {{ dialogTitle }}
          </div>
          <v-spacer></v-spacer>
          <v-icon
            text
            size="27"
            color="white"
            @click="toggleAddEditViewSchoolModal({ show: false })"
            style="cursor: pointer; margin-top: -80px; margin-right: -15px"
            >mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text class="pb-0">
          <v-form class="py-10" ref="schoolForm" lazy-validation>
            <v-row no-gutters>
              <v-col v-if="previewImage" cols="6">
                <img :src="previewImage" alt="" size="100%" class="logo" />
              </v-col>
              <v-col
                v-if="!previewImage && (type === 'edit' || type === 'view')"
                class="px-5 pb-2"
              >
                <v-avatar color="#F9B95A" size="100">
                  <span class="user-initials-text">
                    {{ schoolName.substring(0, 2).toUpperCase() }}
                  </span>
                </v-avatar>
              </v-col>
              <v-col class="file-input-col pt-5 pr-2" cols="5">
                <div v-if="type == 'add' && !previewImage">
                  <v-btn
                    text
                    dark
                    color="#38227A"
                    class="text-capitalize px-5"
                    style="border: 1px solid #38227a; border-radius: 10px"
                    @click="$refs.inputFile.click()"
                    ><span>upload logo</span></v-btn
                  >
                </div>
                <div
                  v-if="
                    type == 'edit' ||
                    (previewImage && (type === 'add' || type === 'edit'))
                  "
                  style="float: right"
                >
                  <v-btn
                    text
                    dark
                    color="#38227A"
                    class="text-capitalize px-5"
                    style="border: 1px solid #38227a; border-radius: 10px"
                    @click="$refs.inputFile.click()"
                    ><span>change logo</span></v-btn
                  >
                </div>

                <v-file-input
                  accept="image/png,image/jpeg,image/svg+xml"
                  label="Upload Logo"
                  prepend-icon
                  outlined
                  color="#7253CF"
                  dense
                  class="file-input"
                  id="inputIds"
                  @change="onUploadLogoChange"
                  style="display: none"
                >
                </v-file-input>
                <label
                  style="display: none"
                  for="inputIds"
                  ref="inputFile"
                ></label>
              </v-col>
              <p v-if="!previewImage && type == 'add'" class="logo-des">
                Please upload a logo for the school,file size should be less
                than 10 MB
              </p>
            </v-row>
            <v-row no-gutters class="mt-3" justify="center">
              <v-col cols="12" class="px-2">
                <v-text-field
                  outlined
                  dense
                  v-model="schoolName"
                  :rules="[rules.required]"
                  label="School Name"
                  class="formFields"
                  color="#7253CF"
                  :disabled="formLoading"
                  :readonly="type === 'view'"
                >
                </v-text-field>
                <span>{{ totalcharacter }} characters</span>
              </v-col>
            </v-row>
            <v-row no-gutters class="mt-3" justify="center">
              <v-col cols="12" class="px-2">
                <v-text-field
                  outlined
                  dense
                  v-model="short_school_name"
                  label="School Short Name"
                  class="formFields"
                  color="#7253CF"
                  :disabled="formLoading"
                  :readonly="type === 'view'"
                  :maxlength="maxchar"
                  @keyup="charCount()"
                >
                </v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters justify="center">
              <v-col cols="12" class="px-2">
                <v-text-field
                  outlined
                  dense
                  v-model="contactPersonName"
                  label="Contact Person Name"
                  color="#7253CF"
                  class="formFields"
                  :rules="[rules.required]"
                  :disabled="formLoading"
                  :readonly="type === 'view'"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters justify="center">
              <v-col cols="6" class="px-2">
                <v-text-field
                  outlined
                  dense
                  v-model="email"
                  @keyup="getSchoolEmailList"
                  label="Contact Person Email"
                  color="#7253CF"
                  class="formFields"
                  :disabled="formLoading"
                >
                </v-text-field>
                <input type="hidden" v-model="hiddenValue1" name="hiddenValue1" />
                <input type="hidden" v-model="hiddenValue2" name="hiddenValue2" />
                <ul>
                  <li v-for="emailList in emailList" :key="emailList.id">
                    <button
                      @click="
                        selectEmail(
                          emailList.username,
                          emailList.user_type,
                          emailList.sub_user_type,
                          $event
                        )
                      "
                    >
                      {{ emailList.username }}
                    </button>
                  </li>
                </ul>
              </v-col>
              <v-col cols="6" class="px-2">
                <v-text-field
                  outlined
                  dense
                  :rules="[rules.mobile]"
                  v-model="mobile"
                  type="number"
                  label="Contact Person Phone"
                  color="#7253CF"
                  class="formFields"
                  :disabled="formLoading"
                  :readonly="type === 'view'"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters justify="center">
              <v-col cols="6" class="px-2">
                <v-text-field
                  outlined
                  dense
                  v-model="role"
                  label="Role"
                  color="#7253CF"
                  class="formFields"
                  :rules="[rules.required]"
                  :disabled="formLoading"
                  :readonly="type === 'view'"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="px-2">
                <v-autocomplete
                  outlined
                  dense
                  v-model="district"
                  :items="districtList"
                  item-text="district_name"
                  item-value="id"
                  label="District"
                  color="#7253CF"
                  class="formFields"
                  :disabled="formLoading"
                  :readonly="type === 'view'"
                  @change="onDistrictChangeHandler"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row no-gutters justify="center">
              <v-col cols="6" class="px-2">
                <v-text-field
                  outlined
                  dense
                  v-model="city"
                  :rules="[rules.required]"
                  label="City"
                  color="#7253CF"
                  class="formFields"
                  :disabled="formLoading"
                  :readonly="type === 'view'"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6" class="px-2">
                <v-autocomplete
                  outlined
                  dense
                  :rules="[rules.required]"
                  v-model="state"
                  :items="statesList"
                  item-text="state_name"
                  item-value="id"
                  label="State"
                  color="#7253CF"
                  class="formFields"
                  :disabled="formLoading"
                  :readonly="type === 'view'"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" class="px-2">
                <v-autocomplete
                  label="Event Support"
                  outlined
                  dense
                  v-model="event_support"
                  :items="eventSupportList"
                  class="formFields"
                  item-text="name"
                  item-value="id"
                  color="#7253CF"
                  :disabled="formLoading"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" class="px-2">
                <v-text-field
                  outlined
                  dense
                  v-model="totalStudents"
                  label="Total Students"
                  color="#7253CF"
                  class="formFields"
                  type="number"
                  :disabled="formLoading"
                  :readonly="type === 'view'"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters justify="center">
              <v-col cols="12" class="px-2">
                <v-textarea
                  outlined
                  dense
                  v-model="shippingAddress"
                  :rules="[rules.required]"
                  label="Shipping Address"
                  color="#7253CF"
                  class="formFields"
                  row-height="8"
                  :disabled="formLoading"
                  :readonly="type === 'view'"
                >
                </v-textarea>
              </v-col>
            </v-row>
            <v-row no-gutters justify="center">
              <v-col cols="6" class="px-2">
                <v-text-field
                  outlined
                  dense
                  v-model="leadGroup"
                  label="Lead Group"
                  color="#7253CF"
                  class="formFields"
                  :rules="[rules.required]"
                  :disabled="formLoading"
                  :readonly="type === 'view'"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6" class="px-2">
                <v-autocomplete
                  outlined
                  dense
                  :items="salesRepList"
                  v-model="salesRep"
                  label="Sales REP"
                  color="#7253CF"
                  class="formFields"
                  item-text="name"
                  item-value="id"
                  :rules="[rules.required]"
                  :disabled="formLoading"
                  :readonly="type === 'view'"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  outlined
                  dense
                  v-model="SpecialMessage"
                  label="Special Message"
                  color="#7253CF"
                  class="formFields"
                  row-height="14"
                  :disabled="formLoading"
                >
                </v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="cardActions pt-0 pb-3">
          <v-spacer></v-spacer>
          <v-btn
            v-if="type == 'add' || type == 'view'"
            :loading="formLoading"
            class="rounded-lg dialogAction-btnText py-5 px-7 mt-5 mr-7"
            dark
            color="#38227A"
            @click="submitSchoolForm"
            :disabled="type === 'view'"
          >
            <span>Add School</span>
          </v-btn>
          <v-btn
            v-if="type == 'edit'"
            class="rounded-lg dialogAction-btnText py-5 px-7 mt-5 mr-7"
            @click="submitSchoolForm"
            dark
            color="#38227A"
          >
            <span>Save</span></v-btn
          >
        </v-card-actions>
        <!-- <div class="footer-class">
          <v-spacer></v-spacer>
          <v-btn
            v-if="type == 'add'"
            class="submit-btn"
            :loading="formLoading"
            dark
            color="#38227A"
            @click="submitSchoolForm"
            >Add school</v-btn
          >
          <v-btn
            v-if="type == 'edit'"
            class="submit-btn-edit"
            dark
            color="#38227A"
            @click="submitSchoolForm"
            >save</v-btn
          >
        </div> -->
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { SALES_REP } from "@/constants/ModuleKeys";
import RULES from "@/common/fieldRules";
import {
  API_MASTER_GET_STATES_LIST,
  API_USER_GET_SALES_REP_LIST,
  API_ADMIN_ADD_SCHOOL,
  API_ADMIN_UPDATE_SCHOOL,
  API_ADMIN_GET_SCHOOL_DETAIL,
  API_USER_GET_DISTRICT_LIST,
  API_MASTER_GET_MESSAGE,
  API_USER_GET_EVENT_SUPPORT_LIST,
  API_SCHOOL_USER_CONNECT_LIST,
} from "@/constants/APIUrls";
import Axios from "@/api/BaseAxios";
export default {
  name: "AddEditViewSchoolModal",
  props: ["DistrictFormData"],
  data() {
    return {
      rules: RULES,
      loading: false,
      formLoading: false,
      previewImage: null,
      LogoImgFile: {},
      schoolName: "",
      salesRep: null,
      salesRepList: [],
      city: "",
      state: null,
      statesList: [],
      contactPersonName: "",
      role: "",
      email: "",
      mobile: null,
      shippingAddress: "",
      leadGroup: "",
      district: -1,
      totalStudents: null,
      districtList: [],
      SpecialMessage: "",
      short_school_name: "",
      totalcharacter: 0,
      maxchar: 20,
      event_support: "",
      eventSupportList: [],
      subUserType: "",
      userTypes: "",
      emailList:[],
    };
  },
  computed: {
    ...mapGetters({
      showGetter: "schoolManagement/getShow",
    }),
    show: {
      get() {
        return this.showGetter;
      },
      set(value) {
        return this.toggleAddEditViewSchoolModal({ show: value });
      },
    },
    type() {
      return this.$store.state.schoolManagement.addEditViewModal.type;
    },
    districtID() {
      return this.$store.state.schoolManagement.addEditViewModal.districtID;
    },
    toastMessage() {
      if (this.type === "add") {
        return "School Added";
      } else {
        return "School Updated";
      }
    },
    dialogTitle() {
      switch (this.type) {
        case "add":
          return "Add New School";
        case "edit":
          return "Edit School";
        case "view":
          return "School details";
        default:
          return "";
      }
    },
  },
  watch: {
    /**
     * Watch modal show hide
     */
    show(value) {
      if (value) {
        // Modal open callback
        setTimeout(() => {
          this.modalOpenCallback();
        }, 100);
      } else {
        // Modal close callback
        this.modalCloseCallback();
      }
    },
  },
  methods: {
    ...mapActions({
      toggleAddEditViewSchoolModal: "schoolManagement/toggleModal",
      showToast: "snackBar/showToast",
    }),
    onUploadLogoChange(payload) {
      // const file = payload.target.files[0]; // use it in case of normal HTML input
      const file = payload; // in case vuetify file input
      if (file.size < 1000000) {
        this.LogoImgFile = payload;
        if (file) {
          this.previewImage = URL.createObjectURL(file);
          URL.revokeObjectURL(file); // free memory
        } else {
          this.previewImage = null;
        }
      } else {
        console.log("image validation error");
        this.showToast({
          message: "Logo file size must be less than 10mb",
          color: "e",
        });
      }
    },
    /**
     * Callback handler on open of modal
     */
    modalOpenCallback() {
      // console.log("modalOpenCallback");
      this.loading = true;
      if (this.districtID) this.district = this.districtID;
      this.getStateList();
      this.getEventSupportList();
    },
    /**
     * Callback handler on close of modal
     */
    modalCloseCallback() {
      this.previewImage = null;
      this.LogoImgFile = {};
      this.schoolName = "";
      this.salesRep = null;
      this.salesRepList = [];
      this.city = "";
      this.state = -1;
      this.statesList = [];
      this.contactPersonName = "";
      this.role = "";
      this.email = "";
      this.mobile = null;
      this.loading = false;
      this.formLoading = false;
      this.shippingAddress = "";
      this.leadGroup = "";
      this.district = -1;
      this.districtList = [];
      this.SpecialMessage = "";
    },
    /**
     * onChange handler for District
     */
    onDistrictChangeHandler(value) {
      var selectedDistrict = this.districtList.filter(
        (district) => district.id === value
      )[0];
      this.city = selectedDistrict.city_name;
      this.state = selectedDistrict.state_id;
    },
    /**
     * GET State List
     */
    getStateList() {
      const self = this;

      const successHandler = function (res) {
        const data = res.data;
        // console.log(data.state_list);
        self.statesList = data.state_list;
      };

      const failureHandler = function (res) {
        const data = res.data;
        console.log(data);
      };

      const finallyHandler = function () {
        self.getSalesRepList();
      };

      const formJson = {};

      return Axios.request_GET(
        API_MASTER_GET_STATES_LIST,
        formJson,
        {},
        successHandler,
        failureHandler,
        false,
        false,
        finallyHandler
      );
    },
    /**
     * GET Sales-Rep List
     */
    getSalesRepList() {
      const self = this;

      const successHandler = function (res) {
        const data = res.data;
        // console.log(data.sales_rep_list);
        self.salesRepList = data.sales_rep_list;
      };

      const failureHandler = function (res) {
        const data = res.data;
        console.log(data);
      };

      const finallyHandler = function () {
        self.getDistrictList();
      };

      const formJson = {};

      return Axios.request_GET(
        API_USER_GET_SALES_REP_LIST,
        formJson,
        {},
        successHandler,
        failureHandler,
        false,
        false,
        finallyHandler
      );
    },
    getEventSupportList() {
      const self = this;
      const successHandler = (res) => {
        const data = res.data;
        self.eventSupportList = data.event_support_staff_list;
      };
      const failureHandler = (res) => {
        console.log(res);
        self.loading = false;
      };
      const finallyHandler = () => {};
      const formJson = {};
      return Axios.request_GET(
        API_USER_GET_EVENT_SUPPORT_LIST,
        formJson,
        {},
        successHandler,
        failureHandler,
        false,
        false,
        finallyHandler
      );
    },
    /**
     * GET District List
     */
    getDistrictList() {
      const self = this;

      const successHandler = function (res) {
        const data = res.data;
        // console.log(data.district_list);
        self.districtList = data.district_list;
      };

      const failureHandler = function (res) {
        const data = res.data;
        console.log(data);
      };

      const finallyHandler = function () {
        if (self.type === "view" || self.type === "edit") {
          self.getSchoolDetails();
        } else {
          self.setFormDataParams();

          self.getSpecialMessage();
        }
      };

      const formJson = {};

      return Axios.request_GET(
        API_USER_GET_DISTRICT_LIST,
        formJson,
        {},
        successHandler,
        failureHandler,
        false,
        false,
        finallyHandler
      );
    },
    getSpecialMessage() {
      const successHandler = (res) => {
        // console.log(res,"special message")
        this.SpecialMessage = res.data.special_message_list[0].message;
        this.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      formData["message_for"] = "school";
      formData["message_type"] = "special_message";
      Axios.request_GET(
        API_MASTER_GET_MESSAGE,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    getSchoolDetails() {
      const self = this;

      const successHandler = (res) => {
        let data = res.data;
        // console.log("success ", data.school_detail);

        // this.previewImage = null;
        this.schoolName = data.school_detail.school_name;
        this.salesRep = data.school_detail.sales_rep;
        this.city = data.school_detail.city_name;
        this.contactPersonName = data.school_detail.user_name;
        this.role = data.school_detail.role;
        this.email = data.school_detail.user_email;
        this.mobile = data.school_detail.user_phone;
        this.state = data.school_detail.state;
        this.previewImage = data.school_detail.logo_url;
        this.shippingAddress = data.school_detail.shipping_address;
        this.leadGroup = data.school_detail.lead_group;
        this.district = data.school_detail.district;
        this.SpecialMessage = data.school_detail.special_message;
        this.totalStudents = data.school_detail.total_student;

        self.loading = false;
      };

      const failureHandler = (res) => {
        console.log("Failure ", res);
        self.loading = false;
      };

      let formData = {};
      formData["school_id"] =
        this.$store.state.schoolManagement.addEditViewModal.schoolID;
      console.log({ formData });

      /**
       * API Call for GET school detail
       */
      Axios.request_GET(
        API_ADMIN_GET_SCHOOL_DETAIL,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    /**
     * Form submit handler
     * @param e
     */
    submitSchoolForm() {
      if (this.$refs.schoolForm.validate()) {
        const self = this;
        self.formLoading = true;
        // console.log("Form validated");

        const successHandler = (res) => {
          let data = res.data;
          console.log("success ", data);
          self.formLoading = false;
          self.toggleAddEditViewSchoolModal({ show: false });
        //  this.$emit("reload");
          this.showToast({
            message: this.toastMessage,
            color: "s",
          });
        };

        const failureHandler = (res) => {
          // console.log("Failure ", res);
          this.showToast({
            message: res,
            color: "e",
          });
          self.formLoading = false;
        };

        let formData = new FormData();
        formData.append("school_name", this.schoolName);
        formData.append("short_school_name", this.short_school_name);
        formData.append("user_name", this.contactPersonName);
        formData.append("user_email", this.email);
        formData.append("user_type", this.hiddenValue1);
        formData.append("sub_user_type", this.hiddenValue2);
        if (this.mobile) formData.append("user_phone", this.mobile);
        formData.append("role", this.role);
        formData.append("city_name", this.city);
        formData.append("state_id", this.state);
        formData.append("event_support", this.event_support);
        formData.append("sales_rep", this.salesRep);
        formData.append("lead_group", this.leadGroup);
        formData.append("shipping_address", this.shippingAddress);
        formData.append("special_message", this.SpecialMessage);
        formData.append("total_student", this.totalStudents);
        if (this.LogoImgFile instanceof File) {
          formData.append("logo", this.LogoImgFile);
        }
        if (this.district !== -1) {
          formData.append("district", this.district);
        }

        if (this.type === "edit") {
          formData.append(
            "school_id",
            this.$store.state.schoolManagement.addEditViewModal.schoolID
          );
        }

        if (this.type === "add") {
          /**
           * API Call for ADD School
           */
          Axios.request_POST(
            API_ADMIN_ADD_SCHOOL,
            formData,
            {},
            successHandler,
            failureHandler,
            false
          );
        } else {
          /**
           * API Call for UPDATE School
           */
          Axios.request_PATCH(
            API_ADMIN_UPDATE_SCHOOL,
            formData,
            {},
            successHandler,
            failureHandler,
            false
          );
        }
      }
    },
    setFormDataParams() {
      if (this.$route.params["id"]) {
        console.log(this.$route.params["id"]);
        this.district = this.$route.params["id"];
        var selectedDistrict = this.districtList.filter(
          (district) => district.id === this.district
        )[0];
        this.city = selectedDistrict.city_name;
        this.state = selectedDistrict.state_id;
        this.salesRep = this.$route.params[SALES_REP];
        // console.log("in toggle");
      }
    },
    charCount: function () {
      if (this.short_school_name.length > this.maxcharacter) {
        alert("max char limit is 30 character");
        return "";
      } else {
        this.totalcharacter = this.short_school_name.length;
      }
    },
    selectEmail(email, additionalValue1, additionalValue2, event) {
      // Prevent the default button click behavior (form submission)
      event.preventDefault();

      // Set the selected email in the input
      this.email = email;
      this.hiddenValue1 = additionalValue1;
      this.hiddenValue2 = additionalValue2;
      // Clear the search results
      this.emailList = [];
    },
    getSchoolEmailList() {
      const self = this;

      const successHandler = function (res) {
        const data = res.data;
        // console.log(data.state_list);
        self.emailList = data.user;
      };

      const failureHandler = function (res) {
        const data = res.data;
        console.log(data);
      };

      const finallyHandler = function () {};

      const formJson = {};
      formJson.search = this.email;
      return Axios.request_GET(
        API_SCHOOL_USER_CONNECT_LIST,
        formJson,
        {},
        successHandler,
        failureHandler,
        false,
        false,
        finallyHandler
      );
    },
  },
  mounted() {},
};
</script>
<style scoped>
.dialogTitle-text {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 315%;
  color: #ffffff;
}
.user-initials-text {
  font-family: Lato;
  font-style: normal;
  font-weight: 800;
  font-size: 17px;
  line-height: 315%;
  color: white;
  letter-spacing: 0px;
}

.dialogAction-btnText >>> span {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 161.5%;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: none !important;
}
.cardActions {
  position: sticky;
  bottom: 0px;
  width: 500px;
  background-color: white;
}
.formFields {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;

  /* identical to box height */

  color: #757575;
}

.logo-preview {
  height: 100px;
  width: 200px;
  position: relative;
  bottom: 30px;
}
.logo-des {
  font-size: 12px;
  margin-left: 13px;
}

.file-input-col {
  margin-left: 13px;
}
img.logo {
  margin-left: 20px;
  max-height: 100px;
  max-width: 200px;
}

.v-card__title {
  background-color: #7253cf;
  color: white;
  height: 100px;
  width: 500px;
  position: sticky;
  display: flex;
  padding: 0px;
  top: 0px;
}
.v-btn >>> span {
  letter-spacing: 0px;
}
@media (min-width: 0px) and (max-width: 600px) {
  .v-card__title {
    width: 100%;
  }
  .cardActions {
    width: 100%;
  }
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
</style>
